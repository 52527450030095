import React from "react";
import DropDown from "./DropDown";
import { Link, useSearchParams } from "react-router-dom";
import { useProductMenu } from "../../apiCalling/useProductMenu";
import { useConcernMenu } from "../../apiCalling/useGetConcern";
import { BasicInformation } from "../../constatnts/information";
// import { useSearchParams } from 'react-router-dom'

export default function Nav({ setMobileToggle }) {
	const { data } = useProductMenu();
	const { data: concernMenu } = useConcernMenu();
	// const [searchParams] = useSearchParams();
	console.log("product menu--", concernMenu);

	return (
		<ul className="cs_nav_list fw-medium text-uppercase">
			<li>
				<Link to="/" onClick={() => setMobileToggle(false)}>
					HOME
				</Link>
			</li>
			<li className="menu-item-has-children">
				<Link to="/about">ABOUT US</Link>
				<DropDown>
					<ul>
						<li>
							<Link to="/about" onClick={() => setMobileToggle(false)}>
								About {BasicInformation.companyName}
							</Link>
						</li>
						<li>
							<Link to="/keyadmin" onClick={() => setMobileToggle(false)}>
								Key Admin
							</Link>
							<Link to="/mission" onClick={() => setMobileToggle(false)}>
								Mission & vission
							</Link>
							<Link to="/corporate-values" onClick={() => setMobileToggle(false)}>
								Corporate Values
							</Link>
							<Link to="/trade-fair" onClick={() => setMobileToggle(false)}>
								Trade Fair
							</Link>
							<Link to="/news" onClick={() => setMobileToggle(false)}>
								News
							</Link>
							<Link to="/certificates" onClick={() => setMobileToggle(false)}>
								Certificates
							</Link>
							<Link to="/crest" onClick={() => setMobileToggle(false)}>
								Crest
							</Link>
							<Link to="/career" onClick={() => setMobileToggle(false)}>
								Career
							</Link>
						</li>
					</ul>
				</DropDown>
			</li>
			<li className="menu-item-has-children">
				<Link to="/concern" onClick={() => setMobileToggle(false)}>
					CONCERN
				</Link>
				<DropDown>
					<ul>
						{concernMenu.map((item, index) => {
							var url = `/concern/${item.url}`;
							if (item.redirectlink)
								return (
									<>
										<li>
											<Link to={item.redirectlink} target="_blank">
												{item.name}
											</Link>
										</li>
									</>
								);
							else
								return (
									<>
										<li>
											<Link to={url} onClick={() => setMobileToggle(false)}>
												{item.name}
											</Link>
										</li>
									</>
								);
						})}
					</ul>
				</DropDown>
			</li>
			<li className="menu-item-has-children">
				<Link to="">OUR PRODUCTS</Link>
				<DropDown>
					<ul>
						{data.map((item, index) => {
							return (
								<>
									<li className="menu-item-has-children">
										<Link to="" onClick={() => setMobileToggle(false)}>
											{item.name}
										</Link>
										<DropDown>
											<ul>
												{item.ourProductSubCategoryVM.map((subItem, index) => {
													return (
														<>
															<li>
																<Link
																	to={`/product/${subItem.id}`}
																	onClick={() => setMobileToggle(false)}
																>
																	{subItem.name}
																</Link>
															</li>
														</>
													);
												})}
											</ul>
										</DropDown>
									</li>
								</>
							);
						})}
					</ul>
				</DropDown>
			</li>
			<li>
				<Link to="/export" onClick={() => setMobileToggle(false)}>
					EXPORT
				</Link>
			</li>
			<li className="menu-item-has-children">
				<Link to="/gallery">GALLERY</Link>
				<DropDown>
					<ul>
						<li>
							<Link to="/gallery" onClick={() => setMobileToggle(false)}>
								PHOTOS
							</Link>
							<Link to="/videos" onClick={() => setMobileToggle(false)}>
								VIDEOS
							</Link>
						</li>
					</ul>
				</DropDown>
			</li>
			<li>
				<Link to="/contact" onClick={() => setMobileToggle(false)}>
					CONTACT US
				</Link>
			</li>
		</ul>
	);
}
