import React, { useEffect, useState } from 'react';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import { useParams } from 'react-router-dom';
import { pageTitle } from '../../helpers/PageTitle';
import axios from 'axios';
import config from '../../config';
import TradeFairDetailsSection from '../Section/TradeFairDetailsSection';
import { useInformation } from '../../apiCalling/useInformatio';

export default function TradeFairDetailsPage() {
  const { tradeFairId } = useParams();
	const [data, setData] = useState([]);
	pageTitle("Trade Fair Details");

	useEffect(() => {
		axios.get(`${config.baseUrl}/api/WebApi/GetTradeFairById?Id=${tradeFairId}`).then((response) => {
			setData(response.data);
		});
	}, [tradeFairId]);
	console.log("Trade Fair Details--", data);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
	const { data:information } = useInformation();
	var BreadcrumbImage = "/images/page_header_1.jpeg";
	if (information?.pageBannerLink)
		BreadcrumbImage = config.baseUrl + information?.pageBannerLink;
  return (
    <>
      <Breadcrumb title="Trade Fair" bgUrl={BreadcrumbImage} />

      <Section pt="50" ptLg="50" pb="50" pbLg="50">
        <TradeFairDetailsSection
          data={data}
          sectionTitle={data?.name}
          sectionTitleUp={data?.description}
        />
      </Section>

    </>
  );
}
