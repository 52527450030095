import parse from 'html-react-parser';
import Button from '../Button';
import config from '../../config';

export default function Hero({
  
imgLink,
  // titleUp,
  // title,
  // subTitle,
  // btnText,
  // btnUrl,
}) {
  return (
    <div className="cs_hero d-flex align-items-center justify-content-center background-filled position-relative overflow-hidden">
      {/* <figure
        className="cs_swiper_parallax_bg"
        style={{ backgroundImage: `url(${config.baseUrl}${imgLink})`, width:'100%', height:'100%' }}
      >
      </figure> */}
      <img src={config.baseUrl+imgLink} style={{width:'100%'}}></img>
    </div>
  );
}
